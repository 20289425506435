
import { computed, defineAsyncComponent, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';
import router from '@/router';

export default defineComponent({
    components: {
        i18nSwitch: defineAsyncComponent(() => import('@/features/i18n/components/i18n-switch.vue')),
    },
    setup: () => ({
        contact: computed(() => translate('app', 'header', 'contact')),
        goToHome: () => router.push('/'),
    }),
});
