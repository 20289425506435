<template>
    <header class="app-header">
        <div class="app-header__container">
            <svg
                role="img"
                aria-label="Mako logo"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 455 95"
                class="app-header__logo"
                @click="goToHome"
            >
                <defs>
                    <path
                        d="M15.99 3.37L52.32 84.28L90.03 3.37L98.52 3.37L102.02 92.03L95.4 92.03L92.16 13.24L55.32 92.03L48.83 92.03L13.37 13.24L10.37 92.03L4 92.03L7.5 3.37L15.99 3.37Z"
                        id="cjHKxWmCa"
                    ></path>
                    <path
                        d="M268.94 3.37L268.94 92.03L262.2 92.03L262.2 3.37L268.94 3.37ZM314.02 3.37L322.63 3.37L279.43 45.95L323.88 92.03L314.14 92.03L270.94 46.57L314.02 3.37Z"
                        id="d4yWUcBX6"
                    ></path>
                    <path
                        d="M360.83 46.57C360.83 21.35 379.31 2 407.28 2C437.37 2 453.11 22.98 453.11 46.7C453.11 74.67 433.63 93.4 406.41 93.4C378.31 93.4 360.83 72.55 360.83 46.57ZM367.95 45.45C367.95 67.8 381.68 87.15 406.78 87.15C433.88 87.15 445.99 67.18 445.99 47.95C445.99 27.72 434 8.24 406.28 8.24C382.68 8.24 367.95 25.22 367.95 45.45Z"
                        id="b1J43iHuHG"
                    ></path>
                    <path
                        d="M212.08 92.03L218.75 92.03L184.17 3.25L177.05 3.5L144.58 92.03L151.39 92.03L180.42 10.74L212.08 92.03Z"
                        id="c4lsv8849m"
                    ></path>
                </defs>
                <g>
                    <g>
                        <g>
                            <use xlink:href="#cjHKxWmCa" opacity="1" fill="#000" fill-opacity="1"></use>
                            <g>
                                <use
                                    xlink:href="#cjHKxWmCa"
                                    opacity="1"
                                    fill-opacity="0"
                                    stroke="#000000"
                                    stroke-width="4"
                                    stroke-opacity="0"
                                ></use>
                            </g>
                        </g>
                        <g>
                            <use xlink:href="#d4yWUcBX6" opacity="1" fill="#000" fill-opacity="1"></use>
                            <g>
                                <use
                                    xlink:href="#d4yWUcBX6"
                                    opacity="1"
                                    fill-opacity="0"
                                    stroke="#fff"
                                    stroke-width="4"
                                    stroke-opacity="0"
                                ></use>
                            </g>
                        </g>
                        <g>
                            <use xlink:href="#b1J43iHuHG" opacity="1" fill="#000" fill-opacity="1"></use>
                            <g>
                                <use
                                    xlink:href="#b1J43iHuHG"
                                    opacity="1"
                                    fill-opacity="0"
                                    stroke="#fff"
                                    stroke-width="4"
                                    stroke-opacity="0"
                                ></use>
                            </g>
                        </g>
                        <g>
                            <use xlink:href="#c4lsv8849m" opacity="1" fill="#000" fill-opacity="1"></use>
                            <g>
                                <use
                                    xlink:href="#c4lsv8849m"
                                    opacity="1"
                                    fill-opacity="0"
                                    stroke="#fff"
                                    stroke-width="4"
                                    stroke-opacity="0"
                                ></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <nav class="app-header__nav">
                <a
                    class="app-header__link"
                    href="mailto:contact@mako.as"
                    target="_blank"
                    rel="noopener noreferrer"
                    :title="`${contact} Mako`"
                    >{{ contact }}</a
                >
            </nav>
            <i18n-switch class="app-header__i18n" />
        </div>
    </header>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';
import router from '@/router';

export default defineComponent({
    components: {
        i18nSwitch: defineAsyncComponent(() => import('@/features/i18n/components/i18n-switch.vue')),
    },
    setup: () => ({
        contact: computed(() => translate('app', 'header', 'contact')),
        goToHome: () => router.push('/'),
    }),
});
</script>

<style lang="scss">
.app-header {
    position: relative;
    z-index: 999;
    &__logo {
        width: 100px;
        margin-right: 2.5rem;
    }
    &__nav {
        margin-left: auto;
        margin-right: 1.25rem;
        display: none;
        @include breakpoint(medium) {
            display: inherit;
        }
    }
    &__link {
        font-size: 1.25rem;
        // color: #fff;
        @include btn-link;
        display: block;
    }
    &__container {
        flex-flow: row nowrap;
        align-items: flex-end;
        @include container;
        padding: 1.5rem 0;
    }
    &__i18n {
        @include breakpoint(small only) {
            margin-left: auto;
        }
    }
}
</style>
